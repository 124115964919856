@import "@wework/ray-core/scss/ray-core";

.footer {
  padding-top: 90px;
  padding-bottom: 50px;
.ray-page-container {
  .ray-grid__cell--span-full {
    .bullets {
      margin: 0 10px 0 10px;
    }
    .terms-privacy {
      color: $ray-color-blue-50;
      margin: 10px 0 10px 0;
    }
  }
}
}

.footer-top-margin {
  margin-bottom: 100px;
}