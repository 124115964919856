@import "@wework/ray-core/scss/ray-core";

.product-list-item-tile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  button.remove-item {
    font-size: 2rem;
    padding: 0;
    width: 60px;
    height: 40px;
  }
}

button.add-item {
  width: 100%;
  margin-bottom: 30px;
}

.gst_in, .tan {
    margin: 1rem 0;
}

.MuiTypography-body1,
.MuiPickersCalendarHeader-dayLabel,
.MuiTypography-body2 {
  font-family: "Apercu" !important;
}

.MuiPickersDay-day {
  .MuiTypography-body2 {
    font-weight: 600 !important;
  }
}
.MuiPickersDay-dayDisabled {
  .MuiTypography-body2 {
    font-weight: normal !important;
  }
}

.MuiPickersDay-daySelected {
  color: $ray-color-black !important;
  background-color: transparent !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

button.MuiPickersDay-current {
  color: #E3E3E3;
  background-color: transparent !important;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

.gstin-field-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 200px;
  margin-top: 30px;
}