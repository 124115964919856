@import "@wework/ray-core/scss/ray-core";

.day-pass {
    .why-wework-icon {
      padding: 0px 15px 0px 0px;
      position: relative;
      top: 6px;
    }

    .why-wework-list {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }


  .safe {
    margin-bottom: 50px;
    margin-top: 50px;
    .safety-text {
      @include ray-breakpoint(phone, tablet) {
        text-align: center;
        display: block;
        margin-left: auto !important;
        margin-bottom: 0;
        font-size: 1.8rem;
      }
    }
    .safety-section {
      text-align: center;
      .img-col {
        margin-top: 50px;
        img {
          margin: auto;
          display: block;
          width: 70px;
          object-fit: contain;
          min-height: 120px;
        }
      }
    }
  }

  .amenities {
    margin-top: 50px;
    margin-bottom: 50px;
    .included-amenities {
      margin-left: 10px;
    }
    .amenities-type {
      display: flex;
      img {
        padding: 10px 20px 20px 10px;
      }
    }
    .covid {
      margin-top: 50px;
    }
  }

  .easy-steps {
    margin-top: 50px;
  .easy-steps-arrow {
    padding-left: 10px;
    padding-right: 10px;
  }
} 

.easy-step-img {
  width: 100%;
  height: auto;
}

.step-text {
  font-weight: 600;
}

.easy-steps-section {
  margin-top: 20px !important;
}