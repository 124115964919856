@import "@wework/ray-core/scss/ray-core";

html {
  scroll-behavior: smooth;
}
html, body {
  font-family: 'Apercu' !important;
}
p{
  font-family: 'Apercu' !important;
}

.loading-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: $ray-color-white;
  top: 0;
  left: 0;
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}

@include ray-breakpoint(phone, tablet) {
  .fixed-height {
    height: 100vh;
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
}

.blur {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px) brightness(0.4);
}

.strikethrough {
  text-decoration: line-through;
  color: $ray-color-gray-30;
}

.not-found {
  background-color: #f5f7fa;
  padding: 150px 0;
  text-align: center;

  .go-back-btn {
    margin-top: 50px;
  }
}

.breadcrumbs {
  padding-top: 50px;
  padding-bottom: 50px;
  color: $ray-color-black;
}

.breadcrumb-links {
  color: $ray-color-black;
  cursor: pointer;
}

.breadcrumb-arrow {
  padding-right: 10px;
  padding-left: 10px;
}

.revert-color {
  color: $ray-color-gray-30;
}

.mx-auto {
  margin: auto;
  display: block !important;
}

.text-center {
  text-align: center !important;
}

.img-fluid {
  width: 100%;
  height: auto;
}

.background-card {
  background-size: cover;
  background-position: center;
  margin: auto;
  padding: 15px 15px 15px 15px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.blank-space-card {
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  width: 100%;
}

.gift-wework-card {
  font-family: Apercu;
  font-style: normal;
  text-align: left;
  color: #512422;
}

.festive-card-btn {
  background-color: #e69a36;
  font-family: Apercu;
  font-style: normal;
  border: 2px solid #e69a36 !important;
}

.festive-card-btn-link {
  text-decoration: none !important;
}
