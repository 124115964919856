


.about {

    ul {
        list-style: disc;
        margin-left: 20px;
    }

    .about-headings {
        margin-top: 50px;
    }
}