@import "@wework/ray-core/scss/ray-core";

.card-container {
  margin-bottom: 50px !important;
}

.intro-location {

  @include ray-breakpoint(phone, tablet) {
    height: 420px;
    @include ray-breakpoint(phone, tablet) {
      height: unset;
    }
  }

  .hero-img {
    height: auto;
    width: 100%;
    max-height: 500px;
    object-fit: cover;
  }

  .hero-text-container {
    background-color: white;
    position: relative;
    width: 92%;
    margin: -100px auto auto auto;
    padding: 50px 50px 0 50px;
    @include ray-breakpoint(phone, tablet) {
      padding: 20px 20px 20px 20px;
      width: 90%;
      margin-top: -50px;
    }
  }
}

.buy-now-heading {
  @include ray-breakpoint(phone, tablet) {
    font-size: 24px !important;
    line-height: normal !important;
  }
}

.get-day-pass {
  color: $ray-color-blue-50;
  @include ray-breakpoint(phone, tablet) {
    text-align: center;
  }
}

.location-list {
  margin-bottom: 50px;
  overflow-x: hidden;
  .availability-text {
    @include ray-breakpoint(phone, tablet) {
      text-align: center;
      display: block;
      margin-left: auto !important;
      font-size: 1.8rem;
    }
  }
  .individual-card {
    margin: 20px auto 20px auto;
    .card-details {
      display: flex;
      flex-direction: column;
      @include ray-breakpoint(phone, tablet) {
        min-height: unset;
      }
      .card-product-title {
        min-height: 64px;
        @include ray-breakpoint(phone, tablet) {
          min-height: unset;
        }
      }
    .card-description {
      margin-bottom: 30px;
      min-height: 75px;
      @include ray-breakpoint(phone, tablet) {
        min-height: unset;
      }
    }
    .card-btn {
      margin: 10px auto 10px auto;
      display: block;
      width: 100%;
      height: auto;
      padding-left: 10px;
    }
  }
}
}

.city-title {
  margin-top: 60px !important;
}

.floating-btn-ref {
  width: 100%;
  margin-top: 50px;
}

.home-float-btn {
  width: 100% !important;
  display: block;
  @include ray-breakpoint(tablet) {
    display: none !important;
  }
}

.textbox-for-desktop {
  @include ray-breakpoint(phone, tablet) {
    display: none;
  }
}

.textbox-for-mobile {
  @include ray-breakpoint(tablet) {
    display: none;
  }
}