@import '@wework/ray-core/scss/ray-core';

.date-picker {

    width: 100%;
    height: 100%;

    h3 {
        margin-bottom: 2rem;
    }

    @include ray-breakpoint(tablet) {
        width: 640px;
        // height: 720px;
    }
      
    button.primary-cta {
        width: 100%;
    }

    .ray-page-container {
        height: 100%;
        padding: 2rem !important;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;
    }
}