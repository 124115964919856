@import "@wework/ray-core/scss/ray-core";

.buildings {
  .ray-breadcrumbs {
    padding: 30px 0px;
  }
  .location-title {
    margin-bottom: 50px;
  }
  .toggle-btn {
    margin-bottom: 30px;
  }

  .buy-daypass {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 30px 0;
    width: 100%;
    z-index: 1;
    background-color: #f5f7fa;
    button {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.carousel {
  margin-top: 50px;
  @include ray-breakpoint(phone, tablet) {
    width: 700px;
  }

  .white-background {
    background-color: $ray-color-white !important;
  img {
    width: 100%;
    height: auto;
    @include ray-breakpoint(phone, tablet) {
      max-width: 80%;
      margin-left: 10px !important;
      margin-right: 10px !important;
      min-height: 205px;
    }
  }
  }

  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    padding: 7px 30px !important;
  }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none;
    @include ray-breakpoint(desktop) {
     display: block;
    }
  }
  .owl-next,
  .owl-prev {
    border-radius: 100% !important;
    position: absolute;
    background-color: $ray-color-gray-03 !important;
    top: 10vw;
    cursor: pointer;
    outline: none;
    .next-prev {
      color: blue;
      font-size: 2.5rem;
      font-weight: lighter;
    }
  }
  .owl-next {
    right: 0;
  }
  .owl-prev {
    left: 0;
  }
  .owl-theme .owl-nav [class*="owl-"]:hover {
    background-color: $ray-color-blue-10 !important;
  }
}
