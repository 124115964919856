@import '@wework/ray-core/scss/ray-core';

#sidebar.active {
  right: 0;
}

#sidebar {
  width: 350px;
  height: 100%;
  background-color: $ray-color-gray-03;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 99;
  @include ray-breakpoint(phone, tablet) {
    width: 100%;
  }
  right: -800px;

  .active {
    left: 0;
  }
  .mobile-list {
    display: none;
  }

  a.building-link {
    font-size: 16px !important;
    font-weight: 400 !important;
    letter-spacing: 0.27px;
  }

  .wework-logo-sidenav {
    width: 115px;
    height: 30px;
    margin: 30px 0 30px 25px;
    display: block;
    object-fit: contain;
    @include ray-breakpoint(phone, tablet) {
      margin-left: 16px;
    }
  }

  .sidebar-cross {
    margin-top: 20px;
    outline: none;
  }

  .ray-page-container {
    p {
      color: $ray-color-blue-50;
      margin-bottom: 20px;
    }
    a {
      line-height: normal;
      display: block;
      text-decoration: none;
      margin-bottom: 15px;
      color: $ray-color-black;
      li {
        color: $ray-color-black;
        padding: 20px auto;
      }
    }

    li::after {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: $ray-color-blue-50;
      transition: width 0.3s;
    }

    li:hover::after {
      width: 30%;
      //transition: width .3s;
    }
  }
  .sidebar--logout__btn {
    cursor: pointer;
  }
  .sidebar--logout__btn:hover,
  .sidebar--account_btn:hover {
    text-decoration: none;
  }
  nav.account__functions {
    padding: 1em 0;
    li::after {
      content: '';
      display: block;
      margin-bottom: 0.5em;
      width: 0;
      height: 2px;
      background: $ray-color-blue-50;
      transition: width 0.3s;
    }
    li:hover::after {
      width: 25%;
    }
  }

  .sidebar-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 1em;
    padding-top: 0;
    width: 100%;
    // margin-left: 20px;
    bottom: 1em;
    // left: 0;
    .bullets {
      margin: 0 5px 0 5px;
    }
  }

  nav {
    ul.ray-page-container {
      padding: 0 25px;
    }
  }
  nav {
    ul.ray-page-container.menu-items-desktop {
      display: flex;
      flex-direction: column;
    }
  }
  @media screen and (min-height: 800px) {
    .secondary-container {
      position: absolute;
      bottom: 1em;
    }
  }
}
