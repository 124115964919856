@import "@wework/ray-core/scss/ray-core";

* {
  // transition: 0.25s ease-in-out;
  box-sizing: border-box;
}

.header-container, .carousel-container {
  padding-top: 1.25rem !important;
  overflow: hidden;
  .logo-col {
    .wework-logo {
      width: 115px;
      height: 25px;
      object-fit: contain;
      margin-top: 12px;
    }
    p {
      color: $ray-color-blue-50;
      font-family: "Apercu";
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.17px;
      text-transform: uppercase;
      margin-left: 10px;
    }
  }

  .right-col {
    img:hover {
      cursor: pointer;
    }
    
    img {
      margin-top: 16px;
      float: right;
      transform: rotate(-110deg);

    }
  #hamburgur {
    outline: none;
    padding-right: 0;
    margin-right: 5px;
    float: right;
  }
  #hamburgur:focus {
    background-color: white;
  }
  .hamburger:hover {
    opacity: 1; 
}
.hamburger.is-active:hover {
  opacity: 1;
}
}
}