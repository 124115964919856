@import "@wework/ray-core/scss/ray-core";

.self__booking_checkbox-row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1em 0;
}

@include ray-breakpoint(phone, tablet) {
  .cancel-section {
    order: 2;
  }

  .continue-section {
    order: 1;
  }
}
