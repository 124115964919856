@import "@wework/ray-core/scss/ray-core";

.carousel-building-heading {
  display: flex;
  align-items: baseline;
}

.card-product-title {
  text-align: center;
  margin-bottom: 0px !important;
}

.individual-card-description {
  text-align: center;
  margin-bottom: 30px !important;
}

.city-card {
  margin-bottom: 50px !important;
}

.individual-card-btn {
  margin: auto !important;
  display: block !important;
  width: 100%;
  margin-top: 1rem !important;
}

.wework-into {
  .get-back-work {
    max-width: 900px;
  }
}

.filter__btn_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  button {
    width: 100%;
    margin-bottom: 1em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    div {
      background-color: #0000ff;
      color: #fff;
      display: flex;
      margin-left: 0.5em;
      padding: 0.3em;
      border-radius: 0.5em;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      span {
        margin-left: 0.3em;
      }
    }
  }
}

.micromarket__row {
  display: none;
}

.sidebar__filter_heading {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  svg {
    font-size: 1.5em;
    margin-right: 0.5em;
  }
  span {
    margin-bottom: 0 !important;
  }
}

.title__row_geo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 2em 1em !important;
  h2 {
    margin-bottom: 0 !important;
  }
}

.map__hide-desktop {
  display: none;
}

.hide__on--Mobile {
  display: none;
}
.near--me__btn-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  button {
    width: 100%;
  }
}

.wework_near_me__btn {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em !important;
  padding: 0.7em !important;
  svg {
    font-size: 2em;
  }
}

@media (min-width: 1195px) {
  .micromarket__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .map__hide-desktop {
    display: block;
  }
  .filter__btn_row {
    display: none;
  }
  .title__row_geo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .desktop-near_me--btn {
    display: flex;
  }
  .wework_near_me__btn-mobile {
    display: none;
  }

  .near--me__btn-row button {
    width: auto;
    min-width: 15%;
  }
  .hide__on--Mobile {
    display: block;
  }
  .wework_near_me__btn {
    padding: 0.5em 1em !important;
    margin-bottom: 0 !important;
  }
}
